import React, { useMemo, useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import { useStoreState } from 'easy-peasy';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Dropdown, Modal } from 'react-bootstrap';
import { IoMdArrowRoundForward, IoIosArrowDown } from 'react-icons/io';

// import AppStore from '../models/AppStore';
import {
  logodark,
  noimageselected,
  sign_in_img,
  search_icon,
  live_status_icon,
  cross_close_icon,
} from './../../assets/Index';

import {
  // RiDownloadLine,
  FaUserCircle,
  FaUserEdit,
  IoLogOut,
} from '../../components/icons/Index';

import './_header.scss';
import { menuOptions } from './helper';
import SearchCollections from './SearchCollection';
import { openUrl } from '../../callbacks';
import { apple, android } from '../../assets/Index';

export default function Header() {
  const location = useLocation();
  const history = useNavigate();
  const authorization = useStoreState((actions) => actions.authorization);
  const newsCount = useStoreState((state) => state.homePageImage);
  const [isAuthorized, setisAuthorized] = useState(false);
  const [showMobileMenuState, setShowMobileMenuState] = useState(false);
  const [showSearchModalState, setShowSearchModalState] = useState(false);
  const [showConference, setShowConferenceState] = useState(
    localStorage.getItem('conference') ? false : true
  );
  const authorizationMaster = JSON.parse(
    localStorage.getItem('AuthorizationMaster')
  );
  const masterEmployees = localStorage.getItem('AuthorizationEmployees');
  const activeRouteName = useMemo(() => {
    const { pathname } = location,
      routePaths = pathname.split('/');
    return `/${routePaths[1]}` || '/';
  }, [location]);
  useEffect(() => {
    if (authorization == null) setisAuthorized(false);
    else setisAuthorized(true);
  }, []);
  const headerRef = useRef(null);

  useEffect(() => {
    function hideMenuOptions() {
      setShowMobileMenuState(false);
    }
    if (showMobileMenuState) {
      window.addEventListener('click', hideMenuOptions);
    } else {
      window.removeEventListener('click', hideMenuOptions);
    }
    return () => {
      window.removeEventListener('click', hideMenuOptions);
    };
  }, [showMobileMenuState]);

  useEffect(() => {
    window.addEventListener('scroll', isSticky);
    return () => {
      window.removeEventListener('scroll', isSticky);
    };
  }, []);
  const isSticky = (e) => {
    const header = document.querySelector('.header-js');
    const scrollTop = window.scrollY;
    scrollTop >= 100
      ? header.classList.add('header-container-fixed')
      : header.classList.remove('header-container-fixed');
    scrollTop >= 180
      ? header.classList.add('header-fixed-top')
      : header.classList.remove('header-fixed-top');
  };

  const logout = () => {
    localStorage.clear();
    window.location.href = '/';
  };

  function menuToggle(event) {
    event.stopPropagation();
    setShowMobileMenuState(!showMobileMenuState);
  }

  function closeSearchModalHndlr() {
    setShowSearchModalState(false);
  }

  function openSearchHndlr(event) {
    event.preventDefault();
    setShowSearchModalState(true);
  }
  function handelConference(close = false) {
    if (!close) {
    }
    localStorage.setItem('conference', 1);
    setShowConferenceState(false);
  }

  function handelSubRouters(link) {
    history(link);
  }
  return (
    <React.Fragment>
      {newsCount && newsCount.conference && showConference ? (
        <div className="position-fixed conference">
          <div className="position-relative">
            <div className="position-absolute live">
              <span className="_span">
                <img
                  src={live_status_icon}
                  alt=""
                  width="12"
                  className="mr-1"
                />
                Event is Live
              </span>
              <span className="span_" onClick={() => handelConference(true)}>
                <img src={cross_close_icon} alt="" />
              </span>
            </div>
            <img
              src={newsCount?.conference?.cover_image}
              alt={newsCount?.conference?.title}
              className="main_img"
            />
          </div>
          <div className="p-3">
            <h5>{newsCount?.conference?.title}</h5>
            <p>{newsCount?.conference?.sub_title}</p>
            <Link
              to={`/conferences/${newsCount?.conference?.slug}`}
              onClick={() => handelConference(false)}
            >
              View Events
            </Link>
          </div>
        </div>
      ) : (
        ''
      )}

      {newsCount && newsCount.is_services_interrupted ? (
        <div
          className="trouble cursor-pointer"
          onClick={() =>
            openUrl(
              newsCount.service_interrupted_url
                ? newsCount.service_interrupted_url
                : ''
            )
          }
        >
          <p>{newsCount.service_interrupted_message}</p>
        </div>
      ) : (
        ''
      )}

      <header className="header-container header-js" ref={headerRef}>
        <nav className="nav-wrapper">
          <Link to="/">
            <img
              id="logo_header"
              src={logodark}
              alt="logo"
              className="brand-logo"
            />
          </Link>
          <div
            className={classNames('menu-collapsable', {
              'show-menu': showMobileMenuState,
            })}
          >
            <div className="mobile-brand-container">
              <img
                id="logo_header"
                src={logodark}
                alt="logo"
                className="brand-logo-mobile"
              />
            </div>

            {!(authorizationMaster || masterEmployees) && (
              <ul className="menu-options">
                {menuOptions.map((item) => {
                  return (
                    <li key={`menu-item-${item.id}`} className="menu-item">
                      <Link
                        to={item.links}
                        className={classNames('menu-link', {
                          active: item.links === activeRouteName,
                        })}
                        onClick={() => {
                          if (item.links == '#') {
                            openUrl(process.env.REACT_APP_PROPOSAL);
                          } else {
                            console.log('');
                          }
                        }}
                      >
                        <div className="d-flex">
                          <img
                            alt="menu icon"
                            src={item.icon}
                            width="18"
                            className="menu-icon"
                            style={{
                              verticalAlign: 'text-top',
                            }}
                          />
                          <img
                            alt="menu icon"
                            src={item.icon_y}
                            width="18"
                            className="menu-icon-yellow"
                            style={{
                              verticalAlign: 'text-top',
                            }}
                          />
                          {item.name}
                        </div>
                        {item.children.length ? (
                          <>
                            <IoIosArrowDown className="ml-2 arrow-icon" />
                            <ul className="menu-dropdown">
                              {item.children.map((child, k) => (
                                <li
                                  key={k}
                                  className={classNames('', {
                                    active: child.links === activeRouteName,
                                  })}
                                >
                                  <div className="d-flex">
                                    <img
                                      alt="menu icon"
                                      src={child.icon}
                                      width="18"
                                      className="menu-icon"
                                      style={{
                                        verticalAlign: 'text-top',
                                      }}
                                    />
                                    <img
                                      alt="menu icon"
                                      src={child.icon_y}
                                      width="18"
                                      className="menu-icon-yellow"
                                      style={{
                                        verticalAlign: 'text-top',
                                      }}
                                    />
                                    <Link
                                      to={child.links}
                                      onClick={() => {
                                        if (child.links == '#') {
                                          openUrl(
                                            process.env.REACT_APP_PROPOSAL
                                          );
                                        } else {
                                          console.log('');
                                        }
                                      }}
                                    >
                                      {' '}
                                      {child.name}
                                    </Link>
                                  </div>

                                  <IoMdArrowRoundForward />
                                </li>
                              ))}
                            </ul>
                          </>
                        ) : (
                          ''
                        )}
                        <>
                          {item.labelCountKey &&
                            newsCount &&
                            Number(newsCount?.news_count) > 0 && (
                              <span className="label-count">
                                {newsCount.news_count}
                              </span>
                            )}
                        </>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            )}
            <button
              onClick={openSearchHndlr}
              className="search-btn"
              aria-label="search"
            >
              <img
                src={search_icon}
                className="search-icon-header"
                alt="Anzali Search"
              />
            </button>
            <div className="mobile-menu-action">
              <a onClick={openSearchHndlr} className="search-link-btn" href="#">
                <img src={search_icon} className="search-icon-mobile" />
                Search
              </a>
              {authorization && (
                <div className="auth-actions">
                  <button
                    className="custom-button border-btn w-100 "
                    onClick={logout}
                    aria-label="logout"
                  >
                    <IoLogOut className="logout-icon mr-3" /> Log out
                  </button>
                </div>
              )}
              {!authorization && (
                <div className="auth-actions">
                  <Link
                    to="/sign-in"
                    className={classNames('signin-link mb-4', {
                      active: '/sign-in' === activeRouteName,
                    })}
                  >
                    Sign In
                  </Link>
                  <button
                    className="custom-button border-btn w-100"
                    aria-label="sign up"
                  >
                    <Link to="/sign-up">
                      <img
                        src={sign_in_img}
                        className="icon mr-2"
                        alt="signup-icon"
                      />
                      <span>Sign Up</span>
                    </Link>
                  </button>
                </div>
              )}
              <div className="mobile-app-icons">
                <hr />
                <h3>GET THE ANZALI APPS</h3>
                <div className="d-flex w-100 link-icon justify-content-between">
                  <img
                    src={apple}
                    onClick={() =>
                      openUrl(
                        'https://apps.apple.com/us/app/anzali/id1604822464'
                      )
                    }
                  />
                  <img
                    src={android}
                    onClick={() =>
                      openUrl(
                        'https://play.google.com/store/apps/details?id=com.nftchills'
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="header-options">
            <div className="header-actions">
              {/* <div className="mr-3 d-block d-lg-none" id="site-header">
                <Link
                  to="#"
                  onClick={() => {
                    setModalShow(true);
                  }}
                  className="downloadIcon"
                >
                  <RiDownloadLine />
                </Link>
              </div> */}
              {isAuthorized ? (
                <div
                  className="sc-btn-top mg-r-12 d-nonfe d-sm-block"
                  id="site-header"
                >
                  <Dropdown className="custom-dropdown pl-0 style-1">
                    <Dropdown.Toggle>
                      <img
                        alt={
                          authorization.data.username
                            ? authorization.data.username
                            : 'username'
                        }
                        src={
                          authorization.data.profile_image
                            ? authorization.data.profile_image
                            : noimageselected
                        }
                      />
                      <span className="ml-3">
                        @
                        {authorization.data.username
                          ? authorization.data.username
                          : 'username'}
                      </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu style={{ margin: 0 }} drop="start">
                      <Dropdown.Item>
                        <Link to="/my-profile">
                          <FaUserCircle /> View Profile
                        </Link>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <Link to="/edit-profile">
                          <FaUserEdit /> Edit Profile
                        </Link>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <Link to="#" onClick={logout}>
                          <IoLogOut /> Log out
                        </Link>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              ) : (
                <>
                  {authorization || masterEmployees ? (
                    <Link
                      to="#"
                      onClick={() => {
                        logout();
                      }}
                      className="sc-button header-slider style style-1 wallet2 fl-button pri-1 ml-3"
                    >
                      <span>Log out</span>
                    </Link>
                  ) : (
                    <>
                      <Link
                        to="/sign-in"
                        className={classNames('signin-link d-none d-lg-block', {
                          active: '/sign-in' === activeRouteName,
                        })}
                      >
                        Sign In
                      </Link>
                      <button
                        className="custom-button border-btn"
                        aria-label="signup"
                      >
                        <Link to="/sign-up">
                          <img
                            src={sign_in_img}
                            className="icon mr-2"
                            alt="signup-icon"
                          />
                          <span>Sign Up</span>
                        </Link>
                      </button>
                    </>
                  )}
                </>
              )}
              <div
                className={classNames('hamburger-icon', {
                  active: showMobileMenuState,
                })}
                // ref={btnToggle}
                onClick={menuToggle}
              >
                <span></span>
              </div>
            </div>
          </div>
        </nav>
        {/* {
                    showSearchModalState &&
                    <SearchCollections
                        closeSearchModalHndlr={closeSearchModalHndlr} />
                } */}
      </header>
      {/* <AppStore show={modalShow} onHide={() => setModalShow(false)} /> */}
      <Modal
        show={showSearchModalState}
        onHide={closeSearchModalHndlr}
        // centered
        // backdrop="static"
        keyboard={false}
      >
        <Modal.Body className="header-searchmodal-body">
          <SearchCollections
            isModalOpen={showSearchModalState}
            closeHndlr={closeSearchModalHndlr}
            authorization={authorization}
          />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
