import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import MainStore from './Store/MainStore';
import { StoreProvider } from 'easy-peasy';
import { ToastContainer } from 'react-toastify';
import App from './App';
import './assets/main/css/style.min.css';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import './assets/main/scss/main.scss';
const renderMethod = module.hot ? ReactDOM.render : ReactDOM.hydrate;
renderMethod(
  <>
    <BrowserRouter>
      <StoreProvider store={MainStore}>
        <App />
        <ScrollToTop />
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          style={{ fontSize: '2rem', minWidth: '50%', maxWidth: '100%' }}
          theme="dark"
        />
      </StoreProvider>
    </BrowserRouter>
  </>,
  document.getElementById('root')
);
