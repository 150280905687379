///Without Auth
// import Home from './Home/Index';
import Condition from '../components/terms/Condition';
import Login from './Auth/Login';
import SignUp from './Auth/SignUp';
import Unsubscribe from './Unsubscribe';
import UserProfile from './profile/UserProfile';
import EditUserProfile from './edit_profile/Edit_Profile';
// import ArticleDetails from './ArticleDetails';
import PeopleProfile from './PeopleProfile';
import Error404 from './Errors/404';
import Terms from './Public/Terms';
import PrivacyUi from './Public/Privacy';
import Portfolio from './Portfolio/Index';
import Collections from './Collections/Index';
import CollectionsList from './Collections/List';
// import Businesses from './Businesses/Index';
import Conferences from './Conferences/Index';
import ConferencesList from './Conferences/List';
import WalletTracker from './WalletTracker';
import Contact from './Contact';
/////Auth
import Privacy from '../components/privacy/Privacy';
import UserNft from './Users/UserNft';
// import Membership from './members/index';
// import Messaging from './Chat/index';
import WalletConnect from './mobile/WalletConnect';
import DisconnectWalletConnect from './mobile/DisconnectWalletConnect';
// import EventDetails from './Events/Details';
// import ZoneEvents from './Zones/Events';
///////////Admin Auth
// import AnnouncementDetails from './Admin/Announcement/Details';
// //
// import UtilityDetail from './Utilities/UtilityDetail';
/////
// import AlertsList from './Alerts/AlertsList';
// import CollectionAlertsList from './Alerts/CollectionAlertsList';
// import News from './News/Index';
import Post from './Users/Post';
////////////////Auth Exmployees
import WalletInfo from './WalletInfo';
import CollectionInfoNew from './CollectionInfo';
import ArtblockCollection from './ArtblockCollection';

import ItemInfoNew from './ItemInfo';
// import { useNavigate } from 'react-router-dom';
//Re
// import Resources from './Resources';
// import ResourceDetails from './Resources/Details';
import React from 'react';
//Blogs
// import Blogs from './Blogs';
// import BlogDetails from './Blogs/Details';

//Home New
import HomeNew from './HomeNew';

//Airdrops
// import Airdrops from './Airdrops';
// import ExploreAirdrops from './ExploreAirdrops';
// import AirdropOverview from './AirdropOverview';

////
import Author from './Author';

const authorization = localStorage.getItem('Authorization');
let routes = [];

// redirectRule();
// function redirectRule() {
//   const getPathName = window.location.pathname;
//   if (getPathName === '/tracker') {
//     window.location.href = '/nft-wallet-tracker';
//   } else if (getPathName === '/news') {
//     window.location.href = '/nft-news';
//   } else if (getPathName === '/collections') {
//     window.location.href = '/nft-collections';
//   } else if (getPathName === '/portfolio') {
//     window.location.href = '/nft-portfolio-tracker';
//   }
//   return;
// }

if (authorization) {
  routes = [
    ////////////////Current Using
    // { path: '/alerts/:id', component: <CollectionAlertsList /> },
    { path: '/edit-profile', component: <EditUserProfile /> },
    // { path: '/rewards/:slug', component: <Messaging /> },
    // { path: 'announcement/detail/:id', component: <AnnouncementDetails /> },
    // { path: 'utility/detail/:id', component: <UtilityDetail /> },
    // { path: 'event/detail/:id', component: <EventDetails /> },
  ];
} else {
  routes = [
    { path: '/sign-in', component: <Login /> },
    { path: '/sign-up', component: <SignUp /> },
  ];
}
routes.push(
  // { path: '/', component: <Home /> },
  { path: '/wallet-connect', component: <WalletConnect /> },
  { path: '/disconnect-wallet', component: <DisconnectWalletConnect /> },
  { path: '/my-profile', component: <UserNft /> },
  { path: '*', component: <Error404 /> },
  { path: '/terms-of-use', component: <Terms /> },
  { path: '/privacy-policy', component: <PrivacyUi /> },
  { path: '/profile/:id', component: <UserProfile /> },
  // { path: '/alerts', component: <AlertsList /> },
  // { path: '/news', component: <News /> },
  // { path: '/news/:id', component: <ArticleDetails /> },
  { path: '/nft/:type/:id', component: <PeopleProfile /> },
  { path: '/terms', component: <Condition /> },
  { path: '/privacy', component: <Privacy /> },
  { path: '/user-profile/:id', component: <UserProfile /> },
  { path: '/post', component: <Post /> },

  // { path: '/irl', component: <Businesses /> },
  { path: '/conferences', component: <Conferences /> },
  { path: '/conferences/:conference_id', component: <ConferencesList /> },
  // { path: '/rewards', component: <Membership /> },

  {
    path: '/wallet-info/:id/:wallet_address/:chain_id',
    component: <WalletInfo />,
  },
  {
    path: '/collection-info/:address/:chain',
    component: <CollectionInfoNew />,
  },
  {
    path: '/artblock-collection/:address/:chain',
    component: <ArtblockCollection />,
  },
  { path: '/item-info/:address/:id/:chain', component: <ItemInfoNew /> },
  // { path: '/lionsmilk', component: <ZoneEvents /> },
  { path: '/unsubscribe/:walletAddress', component: <Unsubscribe /> },
  // { path: '/resources', component: <Resources /> },
  // { path: '/resource/:id', component: <ResourceDetails /> },
  // { path: '/blog', component: <Blogs /> },
  // { path: '/blog/:slug', component: <BlogDetails /> },
  { path: '/', component: <HomeNew /> },
  { path: '/nft-wallet-tracker', component: <WalletTracker /> }, //new
  { path: '/nft-portfolio-tracker', component: <Portfolio /> }, //new
  { path: '/view-collections/:chain', component: <Collections /> }, //new
  { path: '/nft-collections', component: <CollectionsList /> }, //new
  // { path: '/nft-news', component: <News /> }, //new
  // { path: '/news/:id', component: <ArticleDetails /> },
  { path: '/contact-us', component: <Contact /> },
  // { path: '/airdrops/:airdropSlug', component: <AirdropOverview /> },
  // { path: '/airdrops', component: <Airdrops /> },
  // { path: '/explore-airdrops', component: <ExploreAirdrops /> },
  { path: '/author/:name/:id', component: <Author /> }
);
export default routes;
