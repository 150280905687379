import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { apicallback } from '../../callbacks/index';
import PageTitle from '../../components/pagetitle/PageTitle';
const Articles = () => {
  const location = useLocation();
  const { name, id } = useParams();

  const [list, setList] = useState([]);
  const [offset, setOffset] = useState(0);
  const [author, setAuthor] = useState(null);

  useEffect(() => {
    getTopCollections();
  }, []);

  const getTopCollections = async (offset = 0) => {
    const response = await apicallback(
      'content',
      '/articles',
      {
        offset: offset,
        author_id: id,
      },
      null,
      null
    );
    if (response && response.status === 'success') {
      setList(response.data.data);
      setOffset(response.offset);
      setAuthor(response.author);
    }
  };

  return (
    <>
      <PageTitle
        meta={{
          title: 'Your Trusted Source for Web3 & NFT News Alerts | ANZALI',
          description:
            'Stay up to date with the latest news in Web3, NFT, and blockchain. Receive daily insights with curated articles. Short articles are easy to digest.',
        }}
      />
      <section className="collectioninfo-container">
        {/* <div
          class="headerimg-container"
          style='background-image: url("/static/media/Base.5e3ce0bc09d2a75ef990.png");'
        ></div> */}
      </section>
    </>
  );
};

export default Articles;
