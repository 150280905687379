import { Fragment } from 'react';
import loadable from '@loadable/component';
import '../../assets/main/scss/pages/_home_new.scss';
import SectionFirst from './SectionFirst';
import SectionSecond from './SectionSecond';
import Section3 from './Section3';
import SectionFourth from './SectionFourth';
import SectionAirdrop from './SectionAirdrop';
import Section5 from './Section5';
import Section6 from './Section6';
import Section7 from './Section7';
// import Section9 from './Section9';
import Section10 from './Section10';
import MintingCollection from './MintingCollection';
import DigitalMemberShip from './DigitalMemberShip/index';
import PageTitle from '../../components/pagetitle/PageTitle';
// const Articles = loadable(() => import('../../components/layouts/Articles'));
export default function Index() {
  return (
    <Fragment>
      <PageTitle />
      <SectionFirst />
      <SectionSecond />
      <SectionAirdrop />
      <Section3 />
      <Section5 />
      <Section6 />
      <SectionFourth />
      <Section10/>
      <MintingCollection />
      <DigitalMemberShip />
      <Section7 />
      {/* <Section9 /> */}
      {/* <div className="home-custom-container">
        <Articles />
      </div> */}
    </Fragment>
  );
}
